import "@fortawesome/fontawesome-free/css/all.min.css";
import { store } from "app/store";
import "assets/scss/tophat.scss";
import Amplify from "aws-amplify";
import ErrorBoundary from "components/ErrorBoundary";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import awsconfig from "./aws-exports";
import * as serviceWorker from "./serviceWorker";

Amplify.configure(awsconfig);
const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

export const AzureProvider = "AzureAD";
export const PROJECT_API = "projectApi";
export const SUGGEST_API = "suggestApi";
export const TODO_API = "todoApi";
export const TOPHAT_API = "tophatApi";
export const QUICKSIGHT_API = "quicksightApi";
export const CHECKLISTRECORD_API = "checklistRecordApi";
export const ALL_ITEMS = "All Items";
export const PAGE_SIZE = 20;
export const LEAD_SHORT_TO = 8;
export const LEAD_MEDIUM_TO = 22;

export const COST_SHORT_TO = 50;
export const COST_MEDIUM_TO = 150;
export const BUCKET_TOPHAT = "dev-mvp-data-platform-fragmented-data-ingestions";

export const MOCK_IMAGES = [
  "Public/test_uc2/images/image1.jpg",
  "Public/test_uc2/images/image2.png",
  "Public/test_uc2/images/image3.jpg",
  "Public/test_uc2/images/image4.png"
];
export const MOCK_DOCS = [
  "Public/test_uc2/documents/doc_test_1.pdf",
  "Public/test_uc2/documents/doc_test_2.pdf",
  "Public/test_uc2/documents/doc_test_3.pdf"
];

export const materialTypes = [
  { value: "ZHAL", label: "Semi Finished Material" },
  { value: "ZROH", label: "Raw Material" }
];

export const materialTypesLookup = {
  ZHAL: "Semi Finished Material",
  ZROH: "Raw Material"
};

export const materialTypesName = {
  SemiFinishedMaterial: "ZHAL",
  RawMaterial: "ZROH"
};

export const GALAXY_TAB_A7_BREAKPOINT_W = 1340;
// const GALAXY_TAB_A7_BREAKPOINT_H = 800
export const FOLDER = "Public/";
export const DIR_TO_DOC = "test_uc2/documents/";

export const GET_PROJECT_PATH = "/api/project";
export const STAGE_GATE_PATH = "/api/project/stageGate";
export const TODO_PATH = "/api/todo";
export const MAIN_PIC_PROJECT_PATH = "/mainPICProject";
export const DELETE_MAIN_PIC_PROJECT_PATH = "/mainPICProject/delete";
export const GET_PIC_PROJECT_PATH = "/mainPICProject/assignedPicUser";
export const GET_PLANT_PATH = "/api/checklistRecord/plant";
export const YARD_PATH = "/api/checklistRecord/yard";
export const GET_CLIENT_LIST_PATH = "/api/suggest/clientName";
export const GET_DASHBOARD_EMBED_URL = "/api/quicksight/getDashboardEmbedUrl";
export const PUT_IS_FAVOURITE_PATH = "/api/project/isFavourite";
export const CHECKLIST_SUGGESTION_PATH = "/api/suggest/suggestedChecklist";


export const QS_DASHBOARD_PROJECT_OVERVIEW = "2bafed40-c106-4632-b2f3-19573bf6a886";
export const QS_DASHBOARD_MOCKUP_DEPLOYMENT_TRACKER = "00252a72-f7fd-45d0-9f22-5376ef4484e9";
export const QS_DASHBOARD_QUALITY_OVERVIEW = "9d248ded-89a3-4d96-9964-12e097c271b3";
export const QS_DASHBOARD_EXECUTIVE = "49936f69-a470-495e-a9b1-e4259f3ffc29";
export const QS_DASHBOARD_PRODUCTION = "4c696c4d-8a64-40b9-8831-f8b6b265dbfc";

// For UAT: "UAT Inspection Team";
// For PRODUCTION: "PROD Inspection Team";
export const INSPECTION_TEAM_NAME = "Inspection Team";

import { Layout, Spin } from "antd";
import AppHeader from "AppHeader";
import routes from "AppRoutes";
import Sidebar from "components/Sidebar";
import { FC, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserProfile } from "types/systems/auth-state";

const { Content } = Layout;

const AppLayout: FC<{ user?: UserProfile }> = ({ user }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: "/tophat.svg",
          imgTextSrc: "/tophatText.svg",
          imgAlt: "tophat-logo"
        }}
      />
      <Layout className="site-layout">
        <Content>
          <AppHeader user={user as UserProfile} />
          <div className="site-main site-drawer-render-in-current-wrapper">
            <Suspense fallback={<Spin size="large" />}>
              <Switch>
                {routes.map((route, key) =>
                  route.children ? (
                    route.children.map((childRoute, childKey) => (
                      <Route path={childRoute.path} component={childRoute.component} key={`${key}-${childKey}`} />
                    ))
                  ) : (
                    <Route path={route.path} component={route.component} key={key} />
                  )
                )}
                <Redirect from="*" to="/dashboard/all-projects" />
              </Switch>
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

import { BellOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import Auth from "@aws-amplify/auth";
import { Avatar, Badge, Breadcrumb, Button, Divider, Dropdown } from "antd";
import { RECORD_COLUMNS } from "constants/checklist-record";
import _ from 'lodash';
import { useLocalStorage } from "pages/BillOfMaterials/hooks/useLocalStorage";
import { FC, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { UserProfile } from "types/systems/auth-state";

const AppHeader: FC<{ user?: UserProfile }> = ({ user }) => {
  const location = useLocation();
  const [_1, setShowColumnsMaterialCataLog] = useLocalStorage(
    "antdTableColumnMaterialCataLog",
    []
  );
  const [_2, setShowColumnsBomList] = useLocalStorage(
    "antdTableColumnBomList",
    []
  );
  
  const logout: MouseEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();
    setShowColumnsMaterialCataLog([])
    setShowColumnsBomList([])
    localStorage.setItem(RECORD_COLUMNS, "");
    await Auth.signOut();
  };

  return (
    <div className="site-header">
      {/* <Button onClick={() => {
        API.get("tophatApi", "/projectApp", {
          queryStringParameters: {
            firstName: 'a',
            lastName: 'b'
          }
        })
      }}>Test</Button> */}
      <Breadcrumb separator=">">
        {location.pathname
          .split("/")
          .filter((x) => x !== "")
          .map((l) => (
            <Breadcrumb.Item key={l}>{l == "bom-list" ? "BoM List" : _.capitalize(l.replaceAll("-", " "))}</Breadcrumb.Item>
          ))}
      </Breadcrumb>
      <div className="site-header-action">
        <Badge dot>
          <BellOutlined />
        </Badge>
        <Divider type="vertical" />
        <Avatar size="small" icon={<UserOutlined />} />
        <Dropdown
          overlay={
            <div className="site-header-user-menu">
              <div className="user-menu-avatar">
                <Avatar icon={<UserOutlined />} />
              </div>
              <div className="user-menu-name">{user?.attributes?.email}</div>
              <div className="user-menu-email">{user?.username}</div>
              <Divider className="user-menu-devider" />
              <Button onClick={logout}>Sign out</Button>
            </div>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {user?.attributes?.email} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default AppHeader;

import { Spin } from "antd";
import { setUser } from "app/app.slice";
import { RootState } from "app/store";
import { Auth, Hub } from "aws-amplify";
import Login from "pages/Login";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { UserProfile } from "types/systems/auth-state";
import AppLayout from "./AppLayout";
const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user);

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      setIsLoading(true);
      switch (event) {
        case "signIn":
          dispatch(setUser(data));
          break;
        case "signOut":
          dispatch(setUser(undefined));
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
        case "tokenRefresh":
          console.log("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.log("token refresh failed");
          break;
      }
      setIsLoading(false);
    });

    (async () => {
      setIsLoading(true);
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        dispatch(setUser(currentUser));
      } catch (e) {}
      setIsLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={isLoading}>
      {user ? (
        <>
          <div
            id="tooltip-212123"
            style={{
              position: "absolute",
              display: "none",
              zIndex: 1000,
              margin: 0,
              padding: 10,
              backgroundColor: "#ffffff",
              border: "1px solid #cccccc",
              whiteSpace: "nowrap"
            }}
          ></div>
          <AppLayout user={user as UserProfile} />
        </>
      ) : (
        <Login />
      )}
    </Spin>
  );
};

export default App;

import { AppstoreOutlined, CarryOutOutlined, ControlOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { lazy } from "react";
import { Route } from "types/route";

const routes: Route[] = [
  {
    path: "/dashboard/all-projects/:id",
    menu: false,
    name: "Project Detail",
    component: lazy(() => import("pages/Dashboard/AllProjects/detail"))
  },
  {
    name: "Dashboard",
    icon: AppstoreOutlined,
    menu: true,
    children: [
      {
        path: "/dashboard/executive-dashboard",
        name: "Executive Dashboard",
        component: lazy(() => import("pages/Dashboard/ExecutiveDashboard"))
      },
      {
        path: "/dashboard/production-dasboard",
        name: "Production Dashboard",
        component: lazy(() => import("pages/Dashboard/ProductionDashboard"))
      },
      {
        path: "/dashboard/all-projects",
        name: "All Projects",
        component: lazy(() => import("pages/Dashboard/AllProjects"))
      }
    ]
  },
  {
    path: "/bill-of-materials/material-catalog/:id",
    name: "Material Catalog Detail",
    menu: false,
    component: lazy(() => import("pages/BillOfMaterials/MaterialCatalog/components/MaterialCatalogDetail"))
  },
  {
    path: "/bill-of-materials/bom-list/:moduleTypeAndBomNumber",
    name: "BoM List Detail",
    menu: false,
    component: lazy(() => import("pages/BillOfMaterials/BoMList/components/BomListRouter"))
  },
  {
    name: "Bill Of Materials",
    icon: TeamOutlined,
    menu: true,
    children: [
      {
        path: "/bill-of-materials/material-catalog",
        name: "Material Catalog",
        component: lazy(() => import("pages/BillOfMaterials/MaterialCatalog"))
      },
      {
        path: "/bill-of-materials/bom-list",
        name: "BoM List",
        component: lazy(() => import("pages/BillOfMaterials/BoMList"))
      }
    ]
  },
  // {
  //   path: "/design-library",
  //   name: "Design Library",
  //   icon: ProfileOutlined,
  //   menu: true,
  //   component: lazy(() => import("pages/DesignLibrary"))
  // },
  {
    path: "/quality-management/inspection-library/create",
    name: "Create Checklist",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/ChecklistCreate"))
  },
  {
    path: "/quality-management/quality-center/inpsection-record",
    component: lazy(() => import("pages/QualityManagement/ChecklistRecord")),
    menu: false
  },
  {
    path: "/quality-management/inspection-library/:tab",
    name: "Inspection Library",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/ChecklistLibrary"))
  },
  {
    path: "/quality-management/quality-center/:tab",
    name: "Quality Center",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/QualityCenter"))
  },
  {
    name: "Quality Management",
    icon: CarryOutOutlined,
    menu: true,
    children: [
      {
        path: "/quality-management/inspection-library",
        name: "Inspection Library",
        component: lazy(() => import("pages/QualityManagement/ChecklistLibrary"))
      },
      {
        path: "/quality-management/quality-center",
        name: "Quality Center",
        component: lazy(() => import("pages/QualityManagement/QualityCenter"))
      }
    ]
  },
  {
    path: "/module-storage",
    name: "Module Storage",
    icon: ControlOutlined,
    menu: true,
    component: lazy(() => import("pages/ModuleStorage"))
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingOutlined,
    menu: true,
    divider: true,
    component: lazy(() => import("pages/Settings"))
  }
];

export default routes;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:0f2b1bcf-6562-4ea1-ae1a-e30b5c9b9a61",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_re2xFX46d",
    "aws_user_pools_web_client_id": "5qd1kmqqe4d34r8gbjbst2c7eq",
    "oauth": {
        "domain": "tophat-demo.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://demo.tophatmvp.uk/",
        "redirectSignOut": "https://demo.tophatmvp.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tophatstaticfile160916-demo",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "projectApi",
            "endpoint": "https://7eqazbmsmh.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "checklistRecordApi",
            "endpoint": "https://v08mp8v1x9.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "checklistTemplateApi",
            "endpoint": "https://5w90lknl3j.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "entityApi",
            "endpoint": "https://wanyfj5hkd.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "suggestApi",
            "endpoint": "https://vidkpetqvc.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "todoApi",
            "endpoint": "https://sr8d5n2ks1.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "quicksightApi",
            "endpoint": "https://e2ecpk1iy7.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "checklistApi",
            "endpoint": "https://qhy4m34nl8.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "tophatApi",
            "endpoint": "https://p7ycf4m6yf.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
